import { useEffect } from "react";
import * as queryString from "query-string";
import { setTracking } from "utils/tracking";

const useTrackingVisitor = () => {
  useEffect(() => {
    const {
      ref,
      utm_campaign,
      utm_medium,
      utm_source,
      utm_term,
      utm_content,
      code,
      gclid,
      fbclid,
    } = queryString.parse(window.location.search);

    setTracking({
      ref,
      utm_campaign,
      utm_medium,
      utm_source,
      utm_term,
      utm_content,
      code,
      gclid,
      fbclid,
      f_page: window.location.href,
      referrer: document.referrer,
    });
  }, []);
};

export default useTrackingVisitor;
